<template>
  <SideBar icon="user" @toggle="$emit('toggle','')">
    <div>
      <div class="level">
        <div v-show="$store.state.isUserLoggedIn"
             class="level-item hero has-text-danger has-text-centered has-text-weight-semibold">Hi {{ $store.state.username }}!
        </div>
      </div>
      <a class="dropdown-item has-text-weight-semibold " @click="$emit('changeComponent','ChangePassword')">
        <font-awesome-icon class="has-text-grey-dark" icon="key"/>&nbsp;&nbsp;Change Password
      </a>
      <a class="dropdown-item has-text-weight-semibold " @click="$emit('changeComponent','ChangeDisplayName')">
        <font-awesome-icon class="has-text-grey-dark" icon="address-card"/>&nbsp;&nbsp;Change Display Name
      </a>
      <!--<a @click="$emit('changeComponent','DeleteAccount')"  class="dropdown-item has-text-weight-semibold ">
        <font-awesome-icon icon="trash-alt"  class="has-text-grey-dark"/>&nbsp;&nbsp;Delete Account
      </a>-->
      <a class="dropdown-item has-text-weight-semibold " @click="$emit('changePage','/logout')">
        <font-awesome-icon class="has-text-grey-dark" icon="sign-out-alt"/>&nbsp;&nbsp;Logout
      </a>
    </div>
  </SideBar>
</template>

<script>
import SideBar from './Utils/SideBar'

export default {
  name: 'userMenu',
  components: { SideBar }
}
</script>

<style lang="scss" scoped>

</style>
